/*
 * The HeaderMenu only displays if we have an authenticated user.
 * It is responsible for displaying the welcome message and the dropdown menu for logged in users
 * spinner is from : http://tobiasahlin.com/spinkit/
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toggleUserAlertModal } from '../../actions/manageUserAlertActions';
import { toggleEditProfileModal, userLogout } from '../../actions/userActions';
import { Iuser } from '../../models';

import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toggleTutorial } from '../../actions/tutorialActions';
import { constants } from '../../constants/constants';
import HelpManual from '../../images/icons/BM_HelpManual.svg';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import EditProfileModal from './EditProfileModal';
import msalInstance from '../auth/MsalInstance';
interface Iprops extends RouteComponentProps {
    user: Iuser;
    alertsCount: number;
    userLogout: any;
    loading: boolean;
    toggleTutorial: typeof toggleTutorial;

    toggleEditProfileModal: any;
    toggleUserAlertModal: typeof toggleUserAlertModal;
}

interface Istate {
    menuOpen: boolean;
}

const alertImg_Icon = require('../../images/icons/alert_blue.png');
const alertImg_Animation = require('../../images/icons/alert_animated.gif');
class Header extends React.Component<Iprops & WithTranslation, Istate> {
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            menuOpen: false
        };
    }
    handleMenuSelect = (eventKey: any) => {
        switch (eventKey) {
            case 1:
                this.props.toggleEditProfileModal();
                break;
            case 2:
                this.props.userLogout(this.props.t);
                break;
            default:
                break;
        }
    };

    showTutorial() {
        // return false; // jfbloom22 temporarily disabling tutorials for prod deploy
        const { tutorial } = constants;
        const { pathname } = this.props.location;
        const modifiedPathname = pathname.slice(1); // Removes slash

        if (tutorial.hasOwnProperty(modifiedPathname)) {
            return true;
        } else return false;
    }

    isEmailDomainInternal(email: string): boolean {
        const internalDomains = [
            '@beaconmedaes.com',
            '@atlascopco.com',
            '@thebigpixel.net',
            '@class1.com'
        ];

        for (const domain of internalDomains) {
            if (email.includes(domain)) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { t, user } = this.props;
        const isUserFullyLoggedIn =
            user?.id.length &&
            msalInstance.msalApp?.getAllAccounts().length > 0;
        if (!isUserFullyLoggedIn && this.props.loading) {
            return (
                <div className="sk-cube-grid">
                    <div className="sk-cube sk-cube1" />
                    <div className="sk-cube sk-cube2" />
                    <div className="sk-cube sk-cube3" />
                    <div className="sk-cube sk-cube4" />
                    <div className="sk-cube sk-cube5" />
                    <div className="sk-cube sk-cube6" />
                    <div className="sk-cube sk-cube7" />
                    <div className="sk-cube sk-cube8" />
                    <div className="sk-cube sk-cube9" />
                </div>
            );
        } else if (!isUserFullyLoggedIn && !this.props.loading) {
            return null;
        }
        const menuClass = this.state.menuOpen ? 'menu-open' : '';
        return (
            <span>
                {this.props.loading && (
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1" />
                        <div className="sk-cube sk-cube2" />
                        <div className="sk-cube sk-cube3" />
                        <div className="sk-cube sk-cube4" />
                        <div className="sk-cube sk-cube5" />
                        <div className="sk-cube sk-cube6" />
                        <div className="sk-cube sk-cube7" />
                        <div className="sk-cube sk-cube8" />
                        <div className="sk-cube sk-cube9" />
                    </div>
                )}

                <span className="profile">
                    <span className="profile-text">
                        <div className="help-button-wrap">
                            {this.showTutorial() && (
                                <Button
                                    className="tutorial-icon"
                                    bsStyle="link"
                                    aria-label="View Tutorial"
                                    onClick={() => {
                                        this.props.toggleTutorial();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'question-circle']}
                                        size="lg"
                                        style={{
                                            color: constants.colors.iconBlue
                                        }}
                                    />
                                </Button>
                            )}
                            <img
                                src={HelpManual}
                                alt="icon"
                                className="help-icon"
                                onClick={() => {
                                    window.open(
                                        this.isEmailDomainInternal(
                                            this.props.user.email
                                        )
                                            ? 'https://doc.clickup.com/9012013137/d/h/8cjgu2h-3292/26881703f6fb6c9'
                                            : 'https://doc.clickup.com/9012013137/p/h/8cjgu2h-3932/605e4a1c68aef87',
                                        '_blank'
                                    );
                                }}
                                height="30"
                            />
                        </div>
                        <div className="alert-notification-badge">
                            {this.props.alertsCount > 0 ? (
                                <img
                                    src={alertImg_Animation}
                                    alt="icon"
                                    className="notification-icon animation"
                                    onClick={this.props.toggleUserAlertModal}
                                    height="30"
                                />
                            ) : (
                                <img
                                    src={alertImg_Icon}
                                    alt="icon"
                                    className="notification-icon"
                                    onClick={this.props.toggleUserAlertModal}
                                />
                            )}
                            {this.props.alertsCount > 0 ? (
                                <span className="badge">
                                    {this.props.alertsCount}
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                        {t('welcome')}&nbsp;
                        <span className="name">{this.props.user.first}</span>
                        <span className="vertical" />
                    </span>
                    <ButtonToolbar className="header-menu">
                        <Dropdown
                            onToggle={isOpen => {
                                this.setState({ menuOpen: isOpen });
                            }}
                            id="header-dropdown"
                            onSelect={this.handleMenuSelect}
                        >
                            <Dropdown.Toggle
                                bsStyle="link"
                                className="header-menu-button"
                            >
                                <FontAwesomeIcon
                                    icon={['far', 'cog']}
                                    size="lg"
                                />
                            </Dropdown.Toggle>
                            <div className="white-rectangle" />
                            <Dropdown.Menu className={menuClass}>
                                <MenuItem eventKey={1}>
                                    <FontAwesomeIcon icon="user" fixedWidth />{' '}
                                    &nbsp; {t('profile')}
                                </MenuItem>
                                <MenuItem eventKey={2}>
                                    <FontAwesomeIcon
                                        icon={['far', 'sign-out']}
                                        fixedWidth
                                    />{' '}
                                    &nbsp; {t('logout')}
                                </MenuItem>
                                <small
                                    style={{
                                        color: constants.colors.greyText,
                                        padding: '5px 5px 5px 22px'
                                    }}
                                >
                                    v: {process.env.REACT_APP_VERSION}
                                </small>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ButtonToolbar>
                </span>
                <EditProfileModal colorButton="warning" />
            </span>
        );
    }
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & RouteComponentProps<{}>
) => {
    let alertsCount = 0;

    if (isEmpty(state.alertsByID) === false) {
        for (const key of Object.keys(state.alertsByID)) {
            if (state.alertsByID[key].isActive === true) {
                alertsCount++;
            }
        }
    }

    return {
        user: state.user,
        loading: selectIsLoading(state),
        alertsCount
    };
};

export default withRouter(
    withTranslation('common')(
        connect(mapStateToProps, {
            userLogout,
            toggleEditProfileModal,
            toggleUserAlertModal,
            toggleTutorial
        })(Header)
    )
);

import * as React from 'react';

interface Iprops {
    color: string;
    backgroundColor: string;
    label: string;
    value: string;
    unitMeasurement: string;
}

const UnitCard = ({
    color,
    backgroundColor,
    label,
    value,
    unitMeasurement
}: Iprops) => (
    <div
        className="unit-graph"
        style={{
            backgroundColor: `${backgroundColor}`,
            borderColor: `${color}`
        }}
    >
        <div className="unit-wrap">
            <p className="unit-label">
                <b>{label}</b>
            </p>
        </div>
        <p className="unit-value">{`${value} ${unitMeasurement}`}</p>
    </div>
);

export default UnitCard;

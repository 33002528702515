// msalInstance.js

import {
    EventType,
    LogLevel,
    PublicClientApplication
} from '@azure/msal-browser';

class MsalInstance {
    public debug(error: any) {
        let showLogs = false;
        if (showLogs) {
            console.error('[debugMSAL]: ', error);
        }
    }
    // Config settings for the PCA - set in index.tsx
    // Information about all of the config options and what they do can be found here
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
    public msalConfig = {
        auth: {
            clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
            authority: `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_MSAL_SIGNIN_POLICY}`,
            knownAuthorities: [
                `${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com`
            ],
            postLogoutRedirectUri: `${process.env.REACT_APP_HOST_DOMAIN}`,
            redirectUri: `${process.env.REACT_APP_HOST_DOMAIN}`,
            navigateToLoginRequestUrl: false
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false
        },
        system: {
            loggerOptions: {
                loggerCallback: (
                    level: LogLevel,
                    message: string,
                    containsPii: boolean
                ): void => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            this.debug(message);
                            return;
                        case LogLevel.Info:
                            this.debug(message);
                            return;
                        case LogLevel.Verbose:
                            this.debug(message);
                            return;
                        case LogLevel.Warning:
                            this.debug(message);
                            return;
                    }
                },
                piiLoggingEnabled: false
            },
            windowHashTimeout: 60000,
            iframeHashTimeout: 6000,
            loadFrameTimeout: 9000,
            asyncPopups: false
            // If you ever need to test forcing a token to expire
            // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/810
            // tokenRenewalOffsetSeconds: 301
        }
    };

    private static instance: MsalInstance;
    public msalApp: PublicClientApplication;

    public reinstantiateMsal() {
        this.msalApp = new PublicClientApplication(this.msalConfig);
    }

    private constructor() {
        this.msalApp = new PublicClientApplication(this.msalConfig);

        /*
         *   After you complete a successful password reset, you recieve back a token, but
         *   this token cannot be used for sign-in. So check the tfp field in the payload for the
         *   name of the password reset policy, if you find it, logout and clear the token so the user
         *   won't get an error when trying to sign in after changing their password.
         */
        this.msalApp.addEventCallback((event: any) => {
            if (event.eventType === EventType.LOGIN_SUCCESS) {
                if (
                    event.payload.idTokenClaims['tfp'] ===
                    `${process.env.REACT_APP_MSAL_FORGET_PASSWORD_POLICY}`
                ) {
                    this.msalApp.logoutRedirect({
                        postLogoutRedirectUri: '/',
                        account: event.payload.account
                    });
                }
            }
            if (event.eventType === EventType.LOGIN_FAILURE) {
                console.error('[setPCA]: msal redirect login', event.payload);
                document.dispatchEvent(
                    new CustomEvent('startUserLogoutSessionOnly')
                );
            }
            if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                console.error(
                    '[setPCA]: msal redirect acquire token',
                    event.payload
                );
                document.dispatchEvent(
                    new CustomEvent('startUserLogoutSessionOnly')
                );
            }
        });
        this.msalApp.handleRedirectPromise().catch(err => {
            console.error('[setPCA]: handleRedirectPromise error', err);
        });
    }

    public static getInstance(): MsalInstance {
        if (!MsalInstance.instance) {
            MsalInstance.instance = new MsalInstance();
        }
        return MsalInstance.instance;
    }
}

const msalInstance = MsalInstance.getInstance();
Object.freeze(msalInstance);

export default msalInstance;

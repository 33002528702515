/*
 * This component has 2 primary responsibilities
 * 1)  a successful login into Azure
 * If it is an existing user, route to the dashboard or whatever private route they were trying to access before
 * being redirected to the login screen.
 * If it is a new user, route to the signup page
 * 2) provide a plane for the user to begin logging in
 */

import { Button, Col, Grid, Row } from 'react-bootstrap';
import * as localForage from 'localforage';
import initialState from '../../reducers/initialState';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as React from 'react';
import { TFunction } from 'i18next';

import { Iuser, Iredirect } from '../../models';
import { MSALlogin, userLogin, userLogout } from '../../actions/userActions';
import {
    setLoginRedirect,
    removeLoginRedirect,
    setRedirectPathname
} from '../../actions/redirectToReferrerAction';
import { getUserAlerts } from '../../actions/manageUserAlertActions';
import { manualAjaxStart } from '../../actions/ajaxStatusActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import Mixpanel from '../../helpers/Mixpanel';
import msalInstance from './MsalInstance';
import { AuthenticationResult } from '@azure/msal-browser';
import { AxiosError } from 'axios';
interface Iprops extends RouteComponentProps<{}> {
    userLogin: (t: TFunction) => Promise<any>;
    MSALlogin: (t: TFunction) => Promise<any>;
    userLogout: typeof userLogout;
    setLoginRedirect: () => Promise<any>;
    setRedirectPathname: typeof setRedirectPathname;
    removeLoginRedirect: typeof removeLoginRedirect;
    user: Iuser;
    redirect: Iredirect;
    loading: boolean;
    getUserAlerts: typeof getUserAlerts;
    manualAjaxStart: () => void;
}

interface Istate {
    userLoginFailed: boolean;
    reduxPersistDelay: boolean;
    userIsAuthenticated: boolean;
}

const azure = require('../../images/Azure.png');

class Login extends React.Component<Iprops & WithTranslation, Istate> {
    constructor(props: Iprops & WithTranslation) {
        super(props);

        this.state = {
            userLoginFailed: false,
            // delay redirecting to MSAL to give the redirect time to persist to indexDB.
            // this can likely be removed when we switch to the faster redux-persist v4
            reduxPersistDelay: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        this.initApp();
        localForage.setItem(
            'persist:state-core-care-web',
            JSON.stringify(initialState)
        );
    }

    componentWillUnmount() {
        document.removeEventListener(
            'missingUser',
            this.handleRedirectToSignup,
            false
        );
    }

    initApp = () => {
        document.addEventListener(
            'missingUser',
            this.handleRedirectToSignup,
            false
        );

        setTimeout(() => {
            this.setState({ reduxPersistDelay: false });
        }, 4000);

        // store the referring loation in redux
        const locationState: any = this.props.location.state;
        const { from } = locationState || { from: { pathname: '/' } };

        // if we are not already redirecting, then set it
        if (
            !this.props.redirect.redirectToReferrer &&
            from &&
            from.pathname &&
            from.pathname !== '/'
        ) {
            this.props.setRedirectPathname(
                `${from.pathname}${from.search}`,
                true
            );
        }

        const msalAccount = msalInstance.msalApp?.getAllAccounts();

        if (msalAccount?.length > 0) {
            Mixpanel.track('Login Successful');

            if (!this.props.user.id.length) {
                // wait for MSAL to cache the token
                setTimeout(() => {
                    this.props
                        .userLogin(this.props.t)
                        .then(() => {
                            this.props.getUserAlerts();
                        })
                        .catch((error: any) => {
                            console.error('login failed in login.tsx', error);
                            Mixpanel.track('Login Unsuccessful');
                            this.setState({ userLoginFailed: true });
                        });
                }, 200);
            }
        } else {
            console.info('no msal account');
            if (this.props.user.id.length) {
                console.error('token is expired but user is authenticated');
            }
        }

        msalInstance.msalApp
            .handleRedirectPromise()
            .then(this.handleRedirectResponse)
            .catch(this.handleRedirectError);
    };

    handleRedirectResponse = (response: AuthenticationResult | null) => {
        console.info('handleRedirectResponse', response);
        if (response && response.account && !this.state.userIsAuthenticated) {
            this.setState({ userIsAuthenticated: true });
            msalInstance.msalApp.setActiveAccount(response.account);

            // Let the render method handle the redirect instead of direct history push
            this.props
                .userLogin(this.props.t)
                .then(() => {
                    this.props.history.push('/dashboard');
                })
                .catch((error: AxiosError) => {
                    console.info('error', error);
                    if (error.response?.status === 400) {
                        this.handleRedirectToSignup();
                    } else {
                        console.error(
                            'login failed in handleRedirectResponse',
                            error
                        );
                        Mixpanel.track('Login Unsuccessful');
                        this.setState({ userLoginFailed: true });
                    }
                });
        }
    };

    handleRedirectError = (error: any) => {
        this.setState({ userIsAuthenticated: false });
        console.error('Redirect error:', error);
    };

    handleRedirectToSignup = () => {
        this.props.history.push('/signup');
    };

    handleMsalLogin = () => {
        this.props.MSALlogin(this.props.t).then(result => {
            console.info('MSALlogin', result);
        });
    };

    render() {
        const { t } = this.props;
        // handle potential redirects
        const { from } = this.props.redirect.pathname
            ? { from: { pathname: this.props.redirect.pathname } }
            : { from: { pathname: '/' } };
        const { redirectToReferrer } = this.props.redirect;

        // if user is authenticated and exists in the backend
        // redirect to the redirect.pathname or the dashboard
        if (
            msalInstance.msalApp?.getAllAccounts().length > 0 &&
            this.props.user.id.length
        ) {
            const loggedInPath: string = redirectToReferrer
                ? from.pathname
                : '/dashboard';

            Mixpanel.identify(this.props.user.id);
            Mixpanel.people.set({
                $email: this.props.user.email,
                USER_ID: this.props.user.id
            });

            this.props.removeLoginRedirect();

            return <Redirect to={loggedInPath} />;
        }

        return (
            <div className="loginlayout">
                <Grid>
                    <Row>
                        <Col>
                            <div className="login-form login">
                                <span className="loginTitle">
                                    {t('welcome')}
                                </span>
                                <Button
                                    bsStyle="default"
                                    className="loginBtn"
                                    onClick={this.handleMsalLogin}
                                    disabled={this.props.loading}
                                >
                                    <img
                                        width="20"
                                        height="20"
                                        src={azure}
                                        alt="icon"
                                    />
                                    {t('ssoLogin')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: any) => {
    return {
        user: state.user,
        redirect: state.redirect,
        loading: selectIsLoading(state)
    };
};

export default withTranslation('auth')(
    connect(mapStateToProps, {
        userLogin,
        MSALlogin,
        userLogout,
        setLoginRedirect,
        removeLoginRedirect,
        setRedirectPathname,
        getUserAlerts,
        manualAjaxStart
    })(Login)
);

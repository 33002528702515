import { GroupProps, Validators } from 'react-reactive-form';

import { omit } from 'lodash';
import { constants, countryCodeOptions } from '../../constants/constants';
import { Ioption, Iuser } from '../../models';
import { IgenericFormValues } from '../../modelsForms';
import { FormUtil } from './FormUtil';

// reusable user form elements
export const userBaseConfigControls = (
    defaultValues: IgenericFormValues<Iuser> = {
        first: '',
        last: '',
        email: '',
        phone: '',
        mobilePhone: '',
        currency: '',
        mobilePhoneCountryCode: '',
        phoneCountryCode: '',
        phoneExtension: '',
        countryID: constants.countries[constants.defaultCountryID],
        position: ''
    },
    disabled = false,
    includeMobilePhone = true
): { [key: string]: GroupProps } => {
    // Values are either a string or an Ioption, no idea why, so dumb
    if (typeof defaultValues.phoneCountryCode === 'object') {
        defaultValues.phoneCountryCode = (defaultValues.phoneCountryCode as Ioption).value;
    }
    let phoneCountryCode = defaultValues.phoneCountryCode
        ? countryCodeOptions.find(
              x => x.value === defaultValues.phoneCountryCode
          )
        : '';

    if (typeof defaultValues.mobilePhoneCountryCode === 'object') {
        defaultValues.mobilePhoneCountryCode = (defaultValues.mobilePhoneCountryCode as Ioption).value;
    }
    const mobilePhoneCountryCode = defaultValues.mobilePhoneCountryCode
        ? countryCodeOptions.find(
              x => x.value === defaultValues.mobilePhoneCountryCode
          )
        : '';

    let fields = {
        first: {
            options: {
                validators: [FormUtil.validators.requiredWithTrim]
            },
            render: FormUtil.TextInput,
            meta: {
                label: 'user:first',
                colWidth: 6,
                type: 'text',
                name: 'first'
            },
            formState: { value: defaultValues.first, disabled }
        },
        last: {
            options: {
                validators: [FormUtil.validators.requiredWithTrim]
            },
            render: FormUtil.TextInput,
            meta: {
                label: 'user:last',
                colWidth: 6,
                type: 'text',
                name: 'last'
            },
            formState: { value: defaultValues.last, disabled }
        },
        email: {
            options: {
                validators: [
                    FormUtil.validators.requiredWithTrim,
                    Validators.pattern(
                        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/ //eslint-disable-line
                    )
                ]
            },
            render: FormUtil.TextInput,
            meta: {
                label: 'user:email',
                colWidth: 12,
                type: 'text',
                name: 'email'
            },
            formState: { value: defaultValues.email, disabled: true }
        },
        // divider1: {
        //     options: {},
        //     render: FormUtil.Divider
        // },
        mobilePhoneCountryCode: {
            options: {},
            render: FormUtil.Select,
            meta: {
                options: countryCodeOptions,
                label: 'user:mobileCountryCode',
                colWidth: 6,
                placeholder: 'manageUserQueue:countrySearchPlaceholder',
                name: 'mobileCountryCode'
            },
            formState: { value: mobilePhoneCountryCode, disabled }
        },
        mobilePhone: {
            options: {
                validators: [
                    Validators.pattern(
                        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
                    )
                ]
            },
            render: FormUtil.TextInput,
            meta: {
                label: 'user:mobilePhone',
                colWidth: 6,
                type: 'tel',
                placeholder: '***-***-****',
                name: 'mobilePhone'
            },
            formState: { value: defaultValues.mobilePhone, disabled }
        },
        divider2: {
            options: {},
            render: FormUtil.Divider
        },
        phoneCountryCode: {
            options: {},
            render: FormUtil.Select,
            meta: {
                options: countryCodeOptions,
                label: 'user:countryCode',
                colWidth: 6,
                placeholder: 'manageUserQueue:countrySearchPlaceholder',
                name: 'countryCode'
            },
            formState: { value: phoneCountryCode, disabled }
        },
        phone: {
            options: {
                validators: [
                    Validators.pattern(
                        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
                    )
                ]
            },
            render: FormUtil.TextInput,
            meta: {
                label: 'user:phone',
                colWidth: 6,
                type: 'tel',
                placeholder: '***-***-****',
                name: 'phone'
            },
            formState: { value: defaultValues.phone, disabled }
        },
        phoneExtension: {
            options: {},
            render: FormUtil.TextInput,
            meta: {
                label: 'user:extension',
                colWidth: 12,
                type: 'text',
                name: 'phoneExtension'
            },
            formState: { value: defaultValues.phoneExtension, disabled }
        },
        divider3: {
            options: {},
            render: FormUtil.Divider
        },
        position: {
            options: {
                validators: [FormUtil.validators.requiredWithTrim]
            },
            render: FormUtil.TextInput,
            meta: {
                label: 'user:position',
                colWidth: 6,
                type: 'text',
                name: 'position'
            },
            formState: { value: defaultValues.position, disabled }
        },
        countryID: {
            options: {
                validators: FormUtil.validators.requiredWithTrim
            },
            render: FormUtil.Select,
            meta: {
                options: constants.countries,
                label: 'user:country',
                colWidth: 12,
                placeholder: 'manageUserQueue:countrySearchPlaceholder',
                name: 'country'
            },
            formState: { value: defaultValues.countryID, disabled }
        }
    };

    if (includeMobilePhone === false) {
        fields = omit(fields, ['mobilePhone', 'mobilePhoneCountryCode']) as any;
    }

    return fields;
};

import * as React from 'react';
import * as Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Link, match } from 'react-router-dom';
import { TFunction } from 'i18next';

import Alert from '../../../images/alert.svg';
import HourCard from './HourCard';
import UnitCard from './UnitCard';
import { IanalyticsTAEDashboard, IdataFormatPreference } from '../../../models';
import {
    dataUnitEnum,
    GraphType,
    OperatingMode,
    RunStatus
} from '../../../models-enums';
import {
    getGuageChartOptions,
    getGuageChartOptionsDefault
} from '../Utilities';
import { isFloat } from '../../../helpers/isFloat';
import { roundTo } from '../../../helpers/roundTo';
import { AnalyticsToggle } from './AnalyticsToggle';
import AssetImage from '../../common/AssetImage';
import { constants } from '../../../constants/constants';

interface Iprops {
    t: TFunction;
    data: IanalyticsTAEDashboard;
    dataFormatPreference: IdataFormatPreference;
    isDemoMode: boolean;
    match: match;
}

export const floatTrim = (value: number) =>
    isFloat(value) ? roundTo(value, 2) : value;

export const dataThreshold = (
    data: number,
    limits: { [key: string]: number },
    dataPoint: string
): string => {
    if (dataPoint === '509') {
        switch (true) {
            case data <= limits.min:
                return '#007BC3';
            case data > limits.min && data < limits.high:
                return '#D20404';
            default:
                return '#7FC467';
        }
    } else {
        switch (true) {
            case data <= limits.min:
                return '#007BC3';
            case data > limits.min && data <= limits.high:
                return '#7FC467';
            default:
                return '#D20404';
        }
    }
};

const AnalyticsCard = (props: Iprops) => {
    const { data, dataFormatPreference, isDemoMode, t } = props;
    const guages = data.primaryData.filter(
        guage => guage.graphType === GraphType.Guague
    );
    const units = data.primaryData.filter(
        guage => guage.graphType === GraphType.UnitPrimary
    );
    const hours = data.primaryData.filter(
        guage => guage.graphType === GraphType.Numeric
    );
    const [toggleState, setToggleState] = React.useState<boolean>(false);
    const toggleCallback = React.useCallback(() => {
        setToggleState(!toggleState);
    }, [toggleState]);

    if (!data) {
        return null;
    }

    return (
        <div className="form-group analytics-card">
            <div className="device-container">
                <div className="analytics-head">
                    <div className="analytics-header">
                        <p
                            className="device-subTitle"
                            style={{
                                marginRight: data.alarmCount === 0 ? 'auto' : 0
                            }}
                        >
                            {`${t('updated')}: ${moment(
                                data.updateDate || new Date()
                            ).format('hh:mm a, DD-MMM-YY')}`}
                        </p>
                        {data.alarmCount > 0 ? (
                            <div className="alarm">
                                <img
                                    src={Alert}
                                    alt="alarm"
                                    aria-hidden="true"
                                    style={{
                                        height: '12px',
                                        marginRight: '4px',
                                        width: '12px'
                                    }}
                                />
                                <p>{`${data.alarmCount} ${
                                    data.alarmCount > 1
                                        ? t('alarms')
                                        : t('alarm')
                                }`}</p>
                            </div>
                        ) : null}
                    </div>
                    <div className="analytics-title">
                        <h4 className="device-title">{data.installBaseName}</h4>
                        <p>{data.installBaseSerialNumber}</p>
                    </div>
                    <AnalyticsToggle
                        isToggled={toggleState}
                        onToggle={toggleCallback}
                    />
                </div>
                <div className="analytics-body-wrap" data-open={toggleState}>
                    <div className="analytics-body">
                        <div className="analytics-img-graphs">
                            <div
                                className="analytics-img-wrap"
                                style={{
                                    width: '100%'
                                }}
                            >
                                <AssetImage
                                    style={{
                                        height: 'auto',
                                        maxHeight: '305px',
                                        maxWidth: '205px',
                                        width: '100%'
                                    }}
                                    imagePath={data.photoUrl}
                                    mainCategoryID={data.mainCategoryID}
                                />
                            </div>
                            {guages.map(chartData => {
                                const fill = dataThreshold(
                                    chartData.data[0],
                                    {
                                        min: chartData.min,
                                        medium: chartData.medium,
                                        high: chartData.high
                                    },
                                    chartData.pointNumber
                                );

                                return (
                                    <div
                                        key={chartData.label + '000'}
                                        className="chart-wrap"
                                        data-type="guage"
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                ...getGuageChartOptionsDefault({
                                                    t,
                                                    fill
                                                }),
                                                ...getGuageChartOptions(
                                                    t,
                                                    dataFormatPreference,
                                                    chartData
                                                )
                                            }}
                                            {...props}
                                        />
                                        <div className="guage-label-wrap">
                                            <p className="guage-min">
                                                {floatTrim(chartData.min)}
                                            </p>
                                            <p className="guage-max">
                                                {floatTrim(chartData.max)}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="analytics-unit-graphs">
                            {units.map(unitItem => {
                                const currentOperatingMode =
                                    unitItem.operatingMode;
                                let color = constants.colors.smartLinkYellow;
                                let backgroundColor =
                                    constants.colors.smartLinkYellowLight;
                                if (
                                    currentOperatingMode === OperatingMode.Off
                                ) {
                                    color = constants.colors.smartLinkRed;
                                    backgroundColor =
                                        constants.colors.smartLinkRedLight;
                                } else if (
                                    currentOperatingMode ===
                                    OperatingMode.Automatic
                                ) {
                                    color = constants.colors.smartLinkBlue;
                                    backgroundColor =
                                        constants.colors.smartLinkBlueLight;
                                }

                                const label = unitItem.label.replace(
                                    /.*\s([0-9]+)$/g,
                                    'Unit $1'
                                );

                                return (
                                    <UnitCard
                                        key={unitItem.label}
                                        color={color}
                                        backgroundColor={backgroundColor}
                                        label={label}
                                        value={`${floatTrim(
                                            unitItem.data[0]
                                        ).toLocaleString('en-US')}`}
                                        unitMeasurement={
                                            dataUnitEnum[unitItem.unit]
                                        }
                                    />
                                );
                            })}
                        </div>
                        <div className="analytics-hour-graphs">
                            {hours.map((hourItem, index) => (
                                <HourCard
                                    key={hourItem.label}
                                    label={hourItem.label}
                                    value={`${floatTrim(hourItem.data[0])}`}
                                    unitMeasurement={
                                        hourItem.unit === 0
                                            ? ''
                                            : dataUnitEnum[hourItem.unit]
                                    }
                                    slim
                                />
                            ))}
                        </div>
                    </div>
                    <Link
                        to={{
                            pathname: `${props.match.url}/analytics-detail/${data.installBaseID}`,
                            search: `?demo=${isDemoMode}`,
                            state: { data }
                        }}
                    >
                        <Button className="expand-button">
                            {t('viewDetails')}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsCard;

/*
 * UserForm
 * User signs up directly to the platform
 */

import * as React from 'react';
import {
    Validators,
    FormGenerator,
    AbstractControl,
    FieldConfig,
    FormGroup
} from 'react-reactive-form';
import { Col, Button } from 'react-bootstrap';
import {
    constants,
    postalAndStateRequiredCountries
} from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { FormUtil } from '../common/FormUtil';
import { withTranslation, WithTranslation } from 'react-i18next';
import { userBaseConfigControls } from '../common/UserBaseConfigControls';
import msalInstance from './MsalInstance';

const getIsRequiredFromSelectedCountry = (selectedCountryID?: string) => {
    if (
        selectedCountryID === '' ||
        selectedCountryID === undefined ||
        selectedCountryID === null
    ) {
        return {
            state: false,
            postalCode: false
        };
    }

    return postalAndStateRequiredCountries[selectedCountryID];
};

// Field config to configure form
const fieldConfigControls = {
    tempCompany: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'company', colWidth: 12, type: 'text', name: 'company' }
    },
    tempAddress: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: {
            label: 'address',
            colWidth: 8,
            type: 'text',
            name: 'temp-address'
        }
    },
    tempAddress2: {
        render: FormUtil.TextInput,
        meta: {
            label: 'address2',
            colWidth: 4,
            type: 'text',
            name: 'temp-address2'
        }
    },
    tempCity: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'city', colWidth: 5, type: 'text', name: 'temp-city' }
    },
    tempState: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'state', colWidth: 3, type: 'text', name: 'temp-state' }
    },
    tempZip: {
        options: {
            validators: [
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(
                    /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                )
            ]
        },
        render: FormUtil.TextInput,
        meta: { label: 'zip', colWidth: 4, type: 'tel', name: 'temp-zip' }
    },
    countryID: {
        options: {
            validators: FormUtil.validators.requiredWithTrim
        },
        render: FormUtil.Select,
        meta: {
            options: constants.countries,
            label: 'user:country',
            colWidth: 12,
            placeholder: 'manageUserQueue:countrySearchPlaceholder',
            name: 'country'
        }
    }
};
const fieldConfig = {
    controls: {
        ...userBaseConfigControls(undefined, undefined, false),
        ...fieldConfigControls
    }
};

interface Iprops extends React.Props<UserForm> {
    handleSubmit: any;
    handleCancel: any;
    loading: boolean;
}

interface IState {
    email: string;
}

class UserForm extends React.Component<Iprops & WithTranslation, IState> {
    public userForm: FormGroup | any;
    public fieldConfig: FieldConfig;
    private subscription: any;

    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            email: ''
        };
        this.fieldConfig = FormUtil.translateForm(fieldConfig, this.props.t);
    }

    componentDidMount() {
        const msalAccount = msalInstance.msalApp?.getAllAccounts();

        if (msalAccount?.length > 0) {
            const account = msalAccount[0];
            const claims = account.idTokenClaims;
            if (claims) {
                setTimeout(() => {
                    const email = claims.email as string;
                    const firstName = claims.given_name as string;
                    const lastName = claims.family_name as string;

                    this.setState({ email });

                    this.userForm.patchValue({
                        email,
                        first: firstName,
                        last: lastName
                    });
                }, 0);
            }
        }

        this.subscription = this.userForm
            .get('countryID')
            .valueChanges.subscribe((value: any) => {
                if (value && value.value) {
                    this.onCountryChanges(value.value);
                }
            });

        this.userForm.patchValue({
            countryID: {
                value: constants.usCountryID,
                label: 'United States of America'
            }
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onCountryChanges = (value: string) => {
        const stateFormControl = this.userForm.get('tempState');
        const tempZipFormControl = this.userForm.get('tempZip');

        const isStateRequired = getIsRequiredFromSelectedCountry(value)
            ? getIsRequiredFromSelectedCountry(value).state
            : false;
        const isPostalCodeRequired = getIsRequiredFromSelectedCountry(value)
            ? getIsRequiredFromSelectedCountry(value).postalCode
            : false;

        stateFormControl.clearValidators();
        tempZipFormControl.clearValidators();

        if (isStateRequired) {
            stateFormControl.setValidators([
                FormUtil.validators.requiredWithTrim
            ]);
            stateFormControl.patchValue(null);
        } else {
            stateFormControl.patchValue(null);
        }

        if (isPostalCodeRequired) {
            tempZipFormControl.setValidators([
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(
                    /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                )
            ]);
            tempZipFormControl.patchValue(null);
        } else {
            tempZipFormControl.patchValue(null);
        }
    };

    handleSubmit = (evt: React.MouseEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (this.userForm.status === 'INVALID') {
            this.userForm.markAsSubmitted();
            toastr.error(
                this.props.t('validationError'),
                '',
                constants.toastrError
            );
            return;
        }

        if (this.userForm.value.email !== this.state.email) {
            this.userForm.value.email = this.state.email;
            console.log(this.userForm.value);
        }

        console.log(this.userForm.value);

        this.props.handleSubmit(this.userForm.value);
    };
    setForm = (form: AbstractControl) => {
        this.userForm = form;
        this.userForm.meta = {
            handleCancel: this.props.handleCancel,
            cancelText: 'Cancel',
            submitText: 'Sign Up',
            loading: this.props.loading
        };
    };
    render() {
        const { t } = this.props;
        return (
            <>
                <form
                    onSubmit={this.handleSubmit}
                    className="clearfix beacon-form login-form"
                >
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.fieldConfig}
                    />
                    <Col xs={12} className="user-form-buttons">
                        <Button
                            bsStyle="link"
                            type="button"
                            onClick={this.props.handleCancel}
                            style={{ color: 'white' }}
                            disabled={this.props.loading}
                            className="left-side"
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            bsStyle="primary"
                            type="submit"
                            disabled={this.props.loading}
                            className="pull-right"
                        >
                            {t('signUp')}
                        </Button>
                    </Col>
                </form>
            </>
        );
    }
}
export default withTranslation('user')(UserForm);

/*
 * Index is the entry point for the app.
 * Initial routes are here and secondary routes are in TwoPanelLayout
 */
import 'custom-event-polyfill';
import 'react-app-polyfill/stable';
// import project css
import 'bootstrap/dist/css/bootstrap.css';
import 'react-datetime/css/react-datetime.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-toggle/style.css';
// import 'draft-js/dist/Draft.css';
import 'moment/min/locales'; // can improve performance by importing the specific locales we want to support
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './i18n';
import './index.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './registerServiceWorker';

// MSAL imports
import msalInstance from './components/auth/MsalInstance';

import {
    faCaretRight,
    faChevronCircleDown,
    faChevronCircleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faQuestionCircle,
    faSearch,
    faShoppingCart,
    faUser,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import {
    faAngleDoubleDown,
    faAngleDoubleRight,
    faBars,
    faCalendarCheck,
    faCheck,
    faClipboard,
    faClipboardList,
    faClock,
    faCog,
    faDownload,
    faEdit,
    faFilePdf,
    faHistory,
    faHospital,
    faInfoCircle,
    faListAlt,
    faMinus,
    faPaste,
    faPlus,
    faSignOut,
    faSortAmountDown,
    faSortAmountUp,
    faTh,
    faTimes,
    faWrench
} from '@fortawesome/pro-regular-svg-icons';

import { MsalProvider } from '@azure/msal-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { TrackJS } from 'trackjs';
import App from './App';
import { constants } from './constants/constants';
import configureStore from './store/configureStore';

library.add(
    faAngleDoubleDown,
    faAngleDoubleRight,
    faBars,
    faCalendarCheck,
    faCaretRight,
    faCheck,
    faChevronCircleDown,
    faChevronCircleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClipboard,
    faClipboardList,
    faClock,
    faCog,
    faDownload,
    faEdit,
    faFilePdf,
    faHistory,
    faHospital,
    faInfoCircle,
    faListAlt,
    faMinus,
    faPaste,
    faPlus,
    faQuestionCircle,
    faSearch,
    faShoppingCart,
    faSignOut,
    faSortAmountDown,
    faSortAmountUp,
    faTh,
    faTimes,
    faUser,
    faUsers,
    faWrench
);

// const msalInstance = new PublicClientApplication(msalConfig);

/*
 * MSAL loads the app in an iFrame in order to refresh auth tokens silently
 * The issue is that this causes a complete reload of the app.
 * in 1.2.0 they added support for a unique redirecturi that can be outside the SPA:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/MSAL-JS-1.2.0
 * however, in order to support this we will need to modify the web.config to redirect everything to root except this auth.html
 * for now we prefer the solution below which prevents the javascript from running.  This still causes the app to load,
 * but most assets are cached by the browser or by the service worker.
 */
if (!(window !== window.parent && !window.opener)) {
    const { store, persistor } = configureStore();

    // set Axios default header for accepting JSON
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.timeout = constants.httpTimeout;

    // Trackjs

    TrackJS.install({
        token: '7acefdd92da44ad595db60cb7c09af8c',
        application: 'mymedgas',
        version: process.env.REACT_APP_VERSION,
        enabled: !(window.location.host.indexOf('localhost') >= 0)
        // for more configuration options, see https://docs.trackjs.com
    });

    // set the window name for UTA transaction window
    window.name = 'MyMedGas';

    /*
* flush writes the current state to storage - this will be right after USER_LOGOUT_SUCCESS is triggered
in userActions.  then we pause the persistor in order to prevent anything else from being persisted while we logout.
*/
    const handleLogout = () => {
        persistor.flush().then(() => {
            persistor.pause();
            // msalApp.logout();
        });
    };
    document.addEventListener('userLogout', handleLogout, false);

    ReactDOM.render(
        <React.Suspense fallback="loading...">
            <Provider store={store}>
                <MsalProvider instance={msalInstance.msalApp}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </MsalProvider>
            </Provider>
        </React.Suspense>,
        document.getElementById('root') as HTMLElement
    );

    console.info('registering service worker');
    serviceWorker.register({
        onUpdate: registration => {
            document.dispatchEvent(new CustomEvent('newVersionAvailable'));
            if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        }
    });
} else {
    console.info('loading silent refresh in iFrame');
}

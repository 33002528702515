import * as React from 'react';

// MSAL imports
import { withMsal, WithMsalProps } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { debugMSAL } from './components/auth/Auth-Utils';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Iuser } from './models';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import { WithTranslation, withTranslation } from 'react-i18next';

import Header from './components/header/Header';
import Login from './components/auth/Login';
import SignUpDirect from './components/auth/SignUpDirect';
import SignUpWithMS from './components/auth/SignUpWithMS';
import { ToastrState } from './typings/react-redux-toastr';
import { TrackJS } from 'trackjs';
import TwoPaneLayout from './components/common/TwoPaneLayout';
import UserAlertModal from './components/userAlertModal/userAlertModal';
import { closeAllModals } from './actions/commonActions';
import { connect } from 'react-redux';
import { constants } from './constants/constants';
import initialState from './reducers/initialState';
import moment from 'moment';
import { setRedirectPathname } from './actions/redirectToReferrerAction';
import { throttle } from 'lodash';
import { userLogout, userLogoutSessionOnly } from './actions/userActions';
import { getUserAlerts } from './actions/manageUserAlertActions';
import { selectIsLoading } from './reducers/commonReducers';
import { IinitialState } from './reducers';
import { SkipNavContent, SkipNavLink } from './components/skipNav/SkipNav';
import TutorialContainer from './components/tutorial/TutorialContainer';
import Mixpanel from './helpers/Mixpanel';
import { SmartlinkContainer } from './components/dashboard/SmartLinkContainer';
import MobileProvider from './components/common/MobileProvider';
import msalInstance from './components/auth/MsalInstance';
window.addEventListener('beforeinstallprompt', (evt: any) => {
    console.info('install prompt triggered');
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    // e.preventDefault();
    // // Stash the event so it can be triggered later.
    // deferredPrompt = e;
});
const queryClient = new QueryClient();
// moment.locale('de')

// const ErrorPage = (error: any) => <h3>Error: {error}</h3>;
const NoMatch = ({ location }: any) => {
    console.error(`no match for route: ${location.pathname}`);
    return (
        <h3 style={{ padding: '20px' }}>
            <code>{location.pathname}</code> does not exist
        </h3>
    );
};

interface Props extends React.Props<App> {
    user: Iuser;
    setRedirectPathname: (pathname: string, setRedirect?: boolean) => void;
    toastr: ToastrState;
    userLogout: typeof userLogout;
    online: boolean;
    closeAllModals: typeof closeAllModals;
    getUserAlerts: typeof getUserAlerts;
    userLogoutSessionOnly: typeof userLogoutSessionOnly;
    pca: PublicClientApplication;
}
interface State {
    fullScreenLoading: boolean;
    redirectToSocialSignup: boolean;
}

class App extends React.Component<
    Props & WithTranslation & WithMsalProps,
    State
> {
    constructor(props: Props & WithTranslation & WithMsalProps) {
        super(props);
        this.state = {
            fullScreenLoading: false,
            redirectToSocialSignup: false
        };
        this.handleSetRedirect = throttle(this.handleSetRedirect, 5000);
        debugMSAL(this.props.msalContext.instance.getAllAccounts());

        // Set the instance in Auth-Utilis
        debugMSAL(this.props.msalContext.instance);
    }

    componentDidMount() {
        if (this.props.msalContext.instance.getAllAccounts().length > 0) {
            if (this.props.msalContext.instance.getAllAccounts().length > 1) {
                console.info(
                    'multiple msal accounts not supported, logging out'
                );
                this.props.userLogoutSessionOnly();

                return;
            }
            this.props.msalContext.instance.setActiveAccount(
                this.props.msalContext.instance.getAllAccounts()[0]
            );
        }

        this.doubleCheckOfflineStatus();

        document.addEventListener(
            'missingUser',
            this.handleRedirectToSignup,
            false
        );
        document.addEventListener(
            'newVersionAvailable',
            this.handleNewVersion,
            false
        );
        document.addEventListener('startUserLogout', this.handleLogout, false);
        document.addEventListener(
            'startUserLogoutSessionOnly',
            this.props.userLogoutSessionOnly,
            false
        );

        if (this.props.user.email.length) {
            TrackJS.configure({
                userId: this.props.user.email,
                version: process.env.REACT_APP_VERSION
            });

            Mixpanel.track('User Launched App');
            Mixpanel.identify(this.props.user.id);
            Mixpanel.people.set({
                $email: this.props.user.email,
                USER_ID: this.props.user.id
            });
            // const idTokenClaims = msalApp.getAccount().idTokenClaims as any;
            // const authTimeLeft = (idTokenClaims.exp - idTokenClaims.auth_time) / 60
            // console.info('app is loaded and authenticated', `${authTimeLeft} min`);
            // console.info('app is loaded and authenticated', this.props.i18n.language);
        }

        moment.locale(this.props.i18n.language);

        console.info(
            'language: ' + this.props.i18n.language,
            'moment locale: ' + moment.locale(this.props.i18n.language)
        );

        console.log('msalApp', msalInstance.msalApp);
        console.log(
            'msalApp?.getAllAccounts()',
            msalInstance.msalApp?.getAllAccounts()
        );
        if (msalInstance.msalApp?.getAllAccounts().length > 0) {
            setTimeout(() => {
                this.appLaunchSync();
            }, 1000);
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            'missingUser',
            this.handleRedirectToSignup,
            false
        );
        document.removeEventListener(
            'newVersionAvailable',
            this.handleNewVersion,
            false
        );
        document.removeEventListener(
            'startUserLogout',
            this.handleLogout,
            false
        );
        document.removeEventListener(
            'startUserLogoutSessionOnly',
            this.props.userLogoutSessionOnly,
            false
        );
    }

    doubleCheckOfflineStatus = () => {
        console.info('browser online: ', navigator.onLine);
        console.info('redux-offline online:', this.props.online);
        if (window.navigator.onLine) {
            window.dispatchEvent(new Event('online'));
        } else {
            window.dispatchEvent(new Event('offline'));
        }
    };

    /*
     * appLaunchSync
     * retrieve any potentially stale data
     */
    appLaunchSync = () => {
        console.log('appLaunchSync');
        this.props.getUserAlerts();
    };

    handleLogout = () => {
        this.props.userLogout(this.props.t);
    };
    handleSetRedirect = () => {
        const { pathname, search } = window.location;
        this.props.setRedirectPathname(`${pathname}${search}`, true);
    };
    handleRedirectToSignup = () => {
        this.setState({ redirectToSocialSignup: true }, () => {
            this.setState({ redirectToSocialSignup: false });
        });
    };

    PrivateRoute = ({ component: Component, ...rest }: any) => {
        const { user } = this.props;
        const authenticated =
            // msalApp.getAccount() && user.isAuthenticated && user.id.length > 0;
            user.id.length > 0; // do not check validity of token here because we do not handle renewing the token when simply navigating to a new route
        if (authenticated) {
            this.handleSetRedirect();
        }
        return (
            <Route
                {...rest}
                render={(props: any) =>
                    authenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    };

    handleNewVersion = () => {
        toastr.warning('Installing Update', 'Please wait...', {
            ...constants.toastrWarning,
            timeOut: 8000
        });
        setTimeout(() => {
            window.location.reload();
        }, 6000);
    };

    render() {
        const { PrivateRoute } = this;
        if (this.state.redirectToSocialSignup) {
            return (
                <Router>
                    <Redirect to={'/azure_signup'} />
                </Router>
            );
        }
        return (
            <QueryClientProvider client={queryClient}>
                <div className="app">
                    {/* <Loading
            show={!this.props.lastSync && this.props.user.isAuthenticated}
            message={'Loading...'}
            /> */}
                    {/* TODO? Replace route pathnames with constants. */}
                    <MobileProvider />
                    <Router>
                        <div className="main-body-content">
                            <SkipNavLink id="main-content" />
                            <SkipNavContent id="main-content">
                                <Header />
                                <Switch>
                                    <Route exact path="/" component={Login} />
                                    <Route
                                        exact
                                        path="/signup"
                                        component={SignUpDirect}
                                    />
                                    <Route
                                        exact
                                        path="/azure_signup"
                                        component={SignUpWithMS}
                                    />
                                    <PrivateRoute
                                        path="/assetStatus"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/pmp"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/dashboard"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/queue"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/users"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/team"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/inventory"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/brands"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/facility"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/facilityqueue"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/alerts"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/codes"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/products"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/productqueue"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/managejobs"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/leads"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/reports"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/reportBuilder"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/locations"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/training"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/documents"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/quotes"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/quotes_sales"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/manageTraining"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/measurements"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/workorders"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/job_comments"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/asset_photos"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/suggested_parts"
                                        component={TwoPaneLayout}
                                    />
                                    <PrivateRoute
                                        path="/inventory_details"
                                        component={TwoPaneLayout}
                                    />
                                    <Route component={NoMatch} />
                                </Switch>
                            </SkipNavContent>
                            {/* TODO Waiting on Signal R to finish their stuff */}
                            {this.props.user.id && <SmartlinkContainer />}
                            <TutorialContainer />
                            <UserAlertModal />
                            <ReduxToastr
                                position={'top-right'}
                                preventDuplicates={
                                    process.env.NODE_ENV === 'production'
                                }
                                getState={() =>
                                    this.props.toastr || initialState.toastr
                                }
                            />
                        </div>
                    </Router>
                </div>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: any) => {
    return {
        user: state.user,
        loading: selectIsLoading(state),
        toastr: state.toastr,
        online: state.offline.online
    };
};

export default withTranslation('')(
    withMsal(
        connect(mapStateToProps, {
            setRedirectPathname,
            closeAllModals,
            userLogout,
            getUserAlerts,
            userLogoutSessionOnly
        })(App)
    )
);
